import { useMemo } from 'react';

function parseImages({ product, productDetail }) {
  if (productDetail?.images?.length > 0) {
    return productDetail.images.map((productImage) => ({
      id: productImage.id,
      source: productImage.imageKey,
    }));
  }

  if (product?.images?.length > 0) {
    return product.images.map((productImage) => ({
      id: productImage.id,
      source: productImage.imageKey,
    }));
  }

  return [];
}

function parseVideos({ productDetail }) {
  if (typeof productDetail?.videoKey === 'string' && !!productDetail.videoKey) {
    return [
      {
        id: productDetail.id,
        poster: productDetail.videoKey + '.jpg',
        source: productDetail.videoKey,
      },
    ];
  }

  return [];
}

function useProductImagesAndVideos({ product, productDetail }) {
  return useMemo(() => {
    const images = parseImages({
      product: product,
      productDetail: productDetail,
    });
    const videos = parseVideos({
      productDetail: productDetail,
    });

    return [
      ...videos.map((video) => ({
        ...video,
        preview: video.poster,
        type: 'video',
      })),
      ...images.map((image) => ({
        ...image,
        preview: image.source,
        type: 'image',
      })),
    ];
  }, [product?.id, productDetail?.id]);
}

export default useProductImagesAndVideos;
