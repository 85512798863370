import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Checkbox } from '@chakra-ui/react';

function ProductImageAndBadges({
  selectable,
  product,
  productDetail,
  isSelected,
  onSelect,
  displayProductInfo,
}) {
  const { locale } = webCore.contexts.useTranslation();

  return (
    <Box w="100%" position="relative">
      <Flex
        position="absolute"
        top={0}
        left={0}
        alignItems="center"
        justifyContent="space-between"
      >
        {Boolean(productDetail.discountPercentage) && (
          <webApp.components.DiscountBadge
            variant="variant1"
            text={`-${productDetail.discountPercentage}%`}
          />
        )}
      </Flex>
      {selectable && (
        <Box position="absolute" right={1} top={2}>
          <Checkbox
            size="sm"
            colorScheme="black.50"
            borderColor="black.50"
            variant="circular"
            onChange={() => onSelect(productDetail)}
            isChecked={isSelected}
          />
        </Box>
      )}
      <Box
        cursor="pointer"
        onClick={!selectable ? displayProductInfo : undefined}
        bgColor="grey.100"
      >
        <webApp.components.S3Image
          folder={webApp.components.S3Image.folders.FOLDER_PRODUCTS}
          imageKey={
            productDetail?.images?.[0]?.imageKey ||
            product?.images?.[0]?.imageKey ||
            ''
          }
          size={'201x172'}
          alt={productDetail?.title?.[locale] || product?.title?.[locale]}
          borderRadius={{ base: 0, md: '10px 10px 0 0' }}
          objectFit={{ base: 'contain', md: 'cover' }}
          w="100%"
          h={44}
        />
      </Box>
    </Box>
  );
}

export default ProductImageAndBadges;
