import http, { API_CORE } from '../http';

export default function YookassaService() {
  const proxyPayments = async (params) => {
    const response = await http(API_CORE).post(
      '/yookassa/proxy/payments',
      params
    );

    return response.data;
  };

  return {
    proxyPayments: proxyPayments,
  };
}
