/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const PlayVideo = createIcon({
  displayName: 'PlayVideo',
  viewBox: '0 0 24 24',
  defaultProps: {
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2',
    fill: 'none',
  },
  path: <polygon points="5 3 19 12 5 21 5 3" />,
});
