/**
 * @param {{
 *   YookassaService: ReturnType<import('../../services/YookassaService').default>,
 * }} param0
 */
export default function yookassaUseCase({ YookassaService }) {
  const proxyPayments = (params) => YookassaService.proxyPayments(params);

  return {
    proxyPayments: proxyPayments,
  };
}
