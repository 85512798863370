import React from 'react';
import webApp from '@happylife-a/web-app';

function ProductCardSkeleton() {
  const screenSize = webApp.contexts.useScreenSize();

  return (
    <webApp.components.Skeleton.Rectangle
      borderRadius={12}
      height={!screenSize.isMinTablet ? '309px' : '336px'}
    />
  );
}

export default ProductCardSkeleton;
