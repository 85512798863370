import { useEffect } from 'react';

function useLoadScript({ src, onScriptLoad = () => {}, onError = () => {} }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = onScriptLoad;
    script.onerror = onError;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
}

export default useLoadScript;
