import React, { useState, useRef, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import S3Image from '../../S3Image';
import S3Video from '../../S3Video';
import SliderController from '../../SliderController';

const MAX_THUMBNAILS_COUNT = 4;
const scrollbarThumbWidth = 2;
const scrollbarTrackWidth = 3;

export default function SliderV1({ imagesAndVideos, mainBoxH, footerBoxW }) {
  const refThumbnail = useRef(null);
  const refThumbItem = useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const thumbItemWidth = 107;
  const thumbnailWidth = !(imagesAndVideos.length <= MAX_THUMBNAILS_COUNT + 1)
    ? `${imagesAndVideos.length * thumbItemWidth + 1}px`
    : 'full';

  const nextSlide = useCallback(() => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex + 1 === imagesAndVideos.length) {
        refThumbnail.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        return 0;
      }
      if (oldActiveSlideIndex >= MAX_THUMBNAILS_COUNT - 1) {
        // eslint-disable-next-line no-unused-expressions
        refThumbnail.current.scrollTo({
          top: 0,
          left: refThumbnail.current.scrollLeft + thumbItemWidth + 1,
          behavior: 'smooth',
        });
      }
      return oldActiveSlideIndex + 1;
    });
  }, [imagesAndVideos.length]);

  const prevSlide = useCallback(() => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex - 1 === -1) {
        refThumbnail.current.scrollTo({
          top: 0,
          left:
            (imagesAndVideos.length - MAX_THUMBNAILS_COUNT) * thumbItemWidth,
          behavior: 'smooth',
        });
        return imagesAndVideos.length - 1;
      }

      refThumbnail.current.scrollTo({
        top: 0,
        left: refThumbnail.current.scrollLeft - thumbItemWidth,
        behavior: 'smooth',
      });

      return oldActiveSlideIndex - 1 === -1
        ? imagesAndVideos.length
        : oldActiveSlideIndex - 1;
    });
  }, [imagesAndVideos.length]);

  const activeItem = imagesAndVideos?.[activeSlideIndex];
  if (!activeItem) {
    return null;
  }

  return (
    <Box flex={1}>
      <Box h={mainBoxH} pos="relative" borderRadius="10px" width="full">
        {imagesAndVideos.length > 1 && (
          <SliderController
            variant="variant1"
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        )}

        <Flex
          position="relative"
          overflow="hidden"
          borderRadius="10px"
          height="full"
        >
          {activeItem?.type === 'image' ? (
            <S3Image
              folder={S3Image.folders.FOLDER_PRODUCTS}
              imageKey={activeItem.preview}
              size="410x428"
              alt="Product Image"
              objectFit="contain"
              w="100%"
              h="100%"
              borderRadius="10px"
              bgColor="grey.50"
            />
          ) : (
            <S3Video
              folder={S3Video.folders.FOLDER_PRODUCTS}
              videoKey={activeItem.source}
              poster={activeItem.poster}
              posterSize="@0.5"
            />
          )}
        </Flex>
      </Box>

      <Box
        maxW={footerBoxW}
        overflowY="hidden"
        overflowX="auto"
        ref={refThumbnail}
        css={{
          '&::-webkit-scrollbar': {
            width: `${scrollbarThumbWidth}px`,
          },
          '&::-webkit-scrollbar-track': {
            width: `${scrollbarTrackWidth}px`,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: `${2 * scrollbarTrackWidth}px`,
          },
        }}
      >
        <Flex ref={refThumbItem} w={thumbnailWidth} mt={2} gap={4}>
          {imagesAndVideos?.map((imageOrVideo, idx) => (
            <Box
              key={`product-image-${idx}`}
              w="89px"
              h="100px"
              borderColor={
                idx === activeSlideIndex ? 'primary.500' : 'transparent'
              }
              borderWidth={2}
              transition="border 200ms"
              onClick={() => setActiveSlideIndex(idx)}
              borderRadius="10px"
              overflow="hidden"
              cursor="pointer"
            >
              <S3Image
                folder={S3Image.folders.FOLDER_PRODUCTS}
                imageKey={imageOrVideo.preview}
                size="166x200"
                alt="Product Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
