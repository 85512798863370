import React, { useState, useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import utils from '@happylife-a/utils';
import { Box } from '@chakra-ui/react';
import useShowProduct from '../useShowProduct';
import CardContainer from './CardContainer';
import OpenQuickViewButton from './OpenQuickViewButton';
import ProductImageAndBadges from './ProductImageAndBadges';
import ProductNameAndDetails from './ProductNameAndDetails';
import RatingsAndFavorite from './RatingsAndFavorite';

function ProductCardContainer({
  product,
  productDetail,
  enableHover = true,
  selectable = false,
  onSelectProduct,
  selectedProducts,
  isMobile = false,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const slug = product?.slug || product?.id;

  const { displayProductInfo, openQuickViewModal } = useShowProduct({
    productSlug: slug,
    productId: product?.id,
    productDetailId: productDetail?.id,
  });

  const isChecked = useMemo(() => {
    return selectedProducts?.some((selectedProduct) =>
      utils.helpers.id.same(selectedProduct.id, productDetail.id)
    );
  }, [selectedProducts]);

  const cardContent = (
    <CardContainer
      selectable={selectable}
      productDetail={productDetail}
      enableHover={enableHover}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
      onSelectProduct={onSelectProduct}
    >
      <ProductImageAndBadges
        selectable={selectable}
        productDetail={productDetail}
        product={product}
        isSelected={isChecked}
        onSelect={onSelectProduct}
        displayProductInfo={displayProductInfo}
      />

      <Box pt={4} px={2}>
        <ProductNameAndDetails
          selectable={selectable}
          productDetail={productDetail}
          product={product}
          displayProductInfo={displayProductInfo}
        />
        <RatingsAndFavorite
          selectable={selectable}
          productDetail={productDetail}
          product={product}
        />
      </Box>

      {enableHover && (
        <OpenQuickViewButton
          openQuickViewModal={openQuickViewModal}
          isHovering={isHovering}
        />
      )}
    </CardContainer>
  );

  return isMobile ? cardContent : <LazyLoad>{cardContent}</LazyLoad>;
}

export default ProductCardContainer;
