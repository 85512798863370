import React, { useCallback } from 'react';
import webApp from '@happylife-a/web-app';
import { Card, CardBody } from '@chakra-ui/react';

function CardContainer({
  selectable,
  productDetail,
  enableHover,
  isHovering,
  setIsHovering,
  onSelectProduct,
  children,
}) {
  const onMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const screenSize = webApp.contexts.useScreenSize();

  return (
    <Card
      _hover={{
        transform: enableHover && screenSize.isMinTablet && 'scale(1.1)',
        zIndex: 2,
      }}
      transition="transform .25s, height .25s"
      onMouseOver={enableHover ? onMouseOver : undefined}
      onMouseOut={enableHover ? onMouseOut : undefined}
      height={!screenSize.isMinTablet ? '309px' : '336px'}
      position="relative"
      boxShadow="none"
      textDecoration="none"
      {...(selectable && {
        onClick: () => onSelectProduct(productDetail),
      })}
    >
      <CardBody
        p={0}
        transition="border .25s"
        borderColor="grey.300"
        borderBottomWidth={isHovering ? 0 : 1}
        borderRightWidth={1}
        borderLeftWidth={1}
        borderTopWidth={1}
        borderRadius="10px"
        overflow="hidden"
      >
        {children}
      </CardBody>
    </Card>
  );
}

export default CardContainer;
