import React from 'react';

function VideoPlayer({ src, source, muted = false, autoplay = true }) {
  return (
    <video
      autoPlay={autoplay}
      muted={muted}
      src={source || src}
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture={true}
      controls={true}
    />
  );
}

export default VideoPlayer;
