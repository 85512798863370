import React, { useState, useRef, useEffect } from 'react';
import webApp from '@happylife-a/web-app';
import { Box, Flex } from '@chakra-ui/react';
import SliderController from '../../SliderController';

export default function SliderV3({ imagesAndVideos, mainBoxH }) {
  const thumbnailContainer = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const nextSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex >= imagesAndVideos?.length - 1) {
        // eslint-disable-next-line no-unused-expressions
        return 0;
      }
      return oldActiveSlideIndex + 1;
    });
  };
  const prevSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex - 1 === -1) {
        return imagesAndVideos.length - 1;
      }

      return oldActiveSlideIndex - 1 === -1
        ? imagesAndVideos.length
        : oldActiveSlideIndex - 1;
    });
  };

  useEffect(() => {
    setActiveSlideIndex(0);
  }, []);

  const handleAnswerChange = (event) => {
    if (event.code === 'ArrowRight') {
      nextSlide();
    } else if (event.code === 'ArrowLeft') {
      prevSlide();
    }
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    window.document.addEventListener('keydown', handleAnswerChange);
    return () => {
      window.document.removeEventListener('keydown', handleAnswerChange);
    };
  }, []);

  const activeItem = imagesAndVideos[activeSlideIndex];

  return (
    <Box flex={1} padding={4}>
      <Flex
        h={mainBoxH}
        pos="relative"
        borderRadius="10px"
        ref={thumbnailContainer}
        justifyContent="center"
      >
        {activeItem.type === 'image' ? (
          <webApp.components.S3Image
            folder={webApp.components.S3Image.folders.FOLDER_PRODUCTS}
            imageKey={activeItem.preview}
            objectFit="cover"
            w="100%"
            h="100%"
            borderRadius="10px"
          />
        ) : (
          <webApp.components.S3Video
            folder={webApp.components.S3Video.folders.FOLDER_PRODUCTS}
            videoKey={activeItem.source}
            poster={activeItem.poster}
            posterSize="@0.5"
          />
        )}
      </Flex>

      <SliderController
        variant="variant3"
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        activeIndex={activeSlideIndex + 1}
        total={imagesAndVideos?.length}
      />
    </Box>
  );
}
