import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import webCore from '@happylife-a/web-core';
import useLocalizedUrlBuilder from '../../hooks/useLocalizedUrlBuilder';

function useLocalizedHistoryUpdateState({ isPush = true } = {}) {
  const { locale } = webCore.contexts.useTranslation();
  const history = useNavigate();

  const buildNewUrl = useLocalizedUrlBuilder();

  const historyUpdateStateLocalized = useCallback(
    (link, state) => {
      let newLink = link;
      if (typeof link === 'string') {
        newLink = buildNewUrl(null, link, locale);
      }

      history(newLink, {
        replace: !isPush,
        state: state,
      });
    },
    [buildNewUrl, locale, isPush]
  );

  return historyUpdateStateLocalized;
}

export default useLocalizedHistoryUpdateState;
