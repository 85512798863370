import appBranding from '@happylife-a/branding';
import webCore from '@happylife-a/web-core';

const DashboardLayout = webCore.helpers.dynamic(
  () => import('~layouts/Dashboard')
);

// dashboard
// const Dashboard = webCore.helpers.dynamic(
//   () => import('~pages/dashboard/index')
// ); TODO:: maybe we need this latter

// category
const CategoriesList = webCore.helpers.dynamic(
  () => import('~pages/categories/index')
);
const CreateCategory = webCore.helpers.dynamic(
  () => import('~pages/categories/create')
);
const CategoryById = webCore.helpers.dynamic(
  () => import('~pages/categories/[id]/index')
);
const EditCategory = webCore.helpers.dynamic(
  () => import('~pages/categories/[id]/edit')
);
const DefineCategoryForm = webCore.helpers.dynamic(
  () => import('~pages/categories/[id]/form')
);

// delivery partners
const DeliveryPartners = webCore.helpers.dynamic(
  () => import('~pages/delivery-partners/index')
);
const DeliveryPartnerOrders = webCore.helpers.dynamic(
  () => import('~pages/delivery-partners/orders/[id]/index')
);
const DeliveryPartnerSingleOrder = webCore.helpers.dynamic(
  () => import('~pages/delivery-partners/single-order/[id]/index')
);

// pending products
const PendingProducts = webCore.helpers.dynamic(
  () => import('~pages/pending-products/index')
);
const PendingProductById = webCore.helpers.dynamic(
  () => import('~pages/pending-products/[id-or-slug]')
);

// pending sellers
const PendingSellers = webCore.helpers.dynamic(
  () => import('~pages/pending-sellers/index')
);

// product section
// @TODO: this page is making application crash, uncomment only after making sure that it works properly
const ProductSection = webCore.helpers.dynamic(
  () => import('~pages/products-section/index')
);
const ProductSectionList = webCore.helpers.dynamic(
  () => import('~pages/products-section/product-lists/[id]/index')
);
const ProductSectionDetails = webCore.helpers.dynamic(
  () => import('~pages/products-section/product-details/[id]/index')
);

// products list
const ProductsList = webCore.helpers.dynamic(
  () => import('~pages/products-list/index')
);
const ProductsListDetails = webCore.helpers.dynamic(
  () => import('~pages/products-list/product-details/[id]/index')
);

// sellers
const SellersList = webCore.helpers.dynamic(
  () => import('~pages/sellers/index')
);
const SellerById = webCore.helpers.dynamic(
  () => import('~pages/sellers/[id]/index')
);

// create product for seller
const CreateProduct = webCore.helpers.dynamic(
  () => import('~pages/sellers/[id]/create/index')
);
const CreateProductSingleIndex = webCore.helpers.dynamic(
  () => import('~pages/sellers/[id]/create/single/index')
);
const CreateProductSingleById = webCore.helpers.dynamic(
  () => import('~pages/sellers/[id]/create/single/[id-or-slug]')
);
const CreateProductVariationalIndex = webCore.helpers.dynamic(
  () => import('~pages/sellers/[id]/create/variational/index')
);
const CreateProductVariationalById = webCore.helpers.dynamic(
  () => import('~pages/sellers/[id]/create/variational/[id-or-slug]')
);

// users
const UsersList = webCore.helpers.dynamic(() => import('~pages/users/index'));
const UserDetails = webCore.helpers.dynamic(
  () => import('~pages/users/[id]/index')
);
const Favorites = webCore.helpers.dynamic(
  () => import('~pages/users/favorites/index')
);
const Shopping = webCore.helpers.dynamic(
  () => import('~pages/users/shopping/index')
);

// returned orders
const ReturnedOrders = webCore.helpers.dynamic(
  () => import('~pages/returned-orders/index')
);

// price limit
const PriceLimit = webCore.helpers.dynamic(
  () => import('~pages/price-limit/index')
);

// pending orders
const PendingOrders = webCore.helpers.dynamic(
  () => import('~pages/pending-orders/index')
);

// orders list
const OrdersList = webCore.helpers.dynamic(
  () => import('~pages/orders-list/index')
);
const OrdersListById = webCore.helpers.dynamic(
  () => import('~pages/orders-list/[id]')
);

// sort categories
const SortCategoriesList = webCore.helpers.dynamic(
  () => import('~pages/sort-categories/index')
);
const SortCategoryById = webCore.helpers.dynamic(
  () => import('~pages/sort-categories/[id]/index')
);

// banners
const Banners = webCore.helpers.dynamic(() => import('~pages/banners/index'));
const BannerSlides = webCore.helpers.dynamic(
  () => import('~pages/banners/[id]/index')
);

// badges
const Badges = webCore.helpers.dynamic(() => import('~pages/badges/index'));

// customer-support
const Chat = webCore.helpers.dynamic(() => import('~pages/chat'));

//seller-support
const SellerSupport = webCore.helpers.dynamic(
  () => import('~pages/seller-support')
);

// push-notifications
const PushNotifications = webCore.helpers.dynamic(
  () => import('~pages/push-notifications')
);

// grants
const CustomerGrants = webCore.helpers.dynamic(() => import('~pages/grants'));
const AppliedGrands = webCore.helpers.dynamic(
  () => import('~pages/applied-grants')
);

const screens = [
  // dashboard
  // {
  //   pathname: '/dashboard',
  //   screen: Dashboard,
  //   layout: DashboardLayout,
  // }, TODO:: maybe we need this latter

  // category
  {
    pathname: '/categories',
    screen: CategoriesList,
    layout: DashboardLayout,
  },
  {
    pathname: '/categories/create',
    screen: CreateCategory,
    layout: DashboardLayout,
  },
  {
    pathname: '/categories/:id',
    screen: CategoryById,
    layout: DashboardLayout,
  },
  {
    pathname: '/categories/:id/edit',
    screen: EditCategory,
    layout: DashboardLayout,
  },
  {
    pathname: '/categories/:id/form',
    screen: DefineCategoryForm,
    layout: DashboardLayout,
  },

  // delivery partners
  {
    pathname: '/delivery-partners',
    screen: DeliveryPartners,
    layout: null,
  },
  {
    screen: DeliveryPartnerOrders,
    pathname: '/delivery-partners/orders/:id',
  },
  {
    screen: DeliveryPartnerSingleOrder,
    pathname: '/delivery-partners/single-order/:id',
  },

  // pending products
  {
    pathname: '/pending-products',
    screen: PendingProducts,
    layout: DashboardLayout,
  },
  {
    pathname: '/pending-products/:idOrSlug',
    screen: PendingProductById,
    layout: DashboardLayout,
  },

  // pending sellers
  {
    pathname: '/pending-sellers',
    screen: PendingSellers,
    layout: DashboardLayout,
  },

  // product section
  // @TODO: this page is making application crash, uncomment only after making sure that it works properly
  {
    screen: ProductSection,
    pathname: '/products-section',
  },
  {
    screen: ProductSectionList,
    pathname: '/products-section/product-lists/:id',
  },
  {
    screen: ProductSectionDetails,
    pathname: '/products-section/product-details/:id',
  },

  // products list
  {
    screen: ProductsList,
    pathname: '/products-list',
  },
  {
    screen: ProductsListDetails,
    pathname: '/products-list/product-details/:id',
  },

  // sellers
  {
    pathname: '/sellers',
    screen: SellersList,
    layout: DashboardLayout,
  },
  {
    pathname: '/sellers/:id/:tab?',
    screen: SellerById,
    layout: DashboardLayout,
  },

  // create product for seller
  {
    pathname: '/sellers/:id/create',
    screen: CreateProduct,
    layout: DashboardLayout,
  },
  {
    pathname: '/sellers/:id/create/single',
    screen: CreateProductSingleIndex,
    layout: DashboardLayout,
  },
  {
    pathname: '/sellers/:id/create/single/:idOrSlug',
    screen: CreateProductSingleById,
    layout: DashboardLayout,
  },
  {
    pathname: '/sellers/:id/create/variational',
    screen: CreateProductVariationalIndex,
    layout: DashboardLayout,
  },
  {
    pathname: '/sellers/:id/create/variational/:idOrSlug',
    screen: CreateProductVariationalById,
    layout: DashboardLayout,
  },

  // pending orders
  {
    pathname: '/pending-orders',
    screen: PendingOrders,
  },

  //Orders list
  {
    pathname: '/orders-list',
    screen: OrdersList,
  },
  {
    pathname: '/orders-list/:id',
    screen: OrdersListById,
  },

  // users
  {
    pathname: '/users',
    screen: UsersList,
  },
  {
    pathname: '/users/:id',
    screen: UserDetails,
    layout: DashboardLayout,
  },
  {
    pathname: '/users/favorites/:id',
    screen: Favorites,
    layout: DashboardLayout,
  },
  {
    pathname: '/users/shopping-bag/:id',
    screen: Shopping,
    layout: DashboardLayout,
  },
  {
    pathname: '/returned-orders',
    screen: ReturnedOrders,
  },
  {
    pathname: '/price-limit',
    screen: PriceLimit,
  },

  // sort categories
  {
    pathname: '/sort-categories',
    screen: SortCategoriesList,
  },
  {
    pathname: '/sort-categories/:id',
    screen: SortCategoryById,
  },

  //banners
  {
    pathname: '/banners',
    screen: Banners,
  },
  {
    pathname: '/slides/:id',
    screen: BannerSlides,
  },

  //badges
  ...(appBranding.tools.features.isEnabled('AdminBadges')
    ? [
        {
          pathname: '/badges',
          screen: Badges,
        },
      ]
    : []),

  //customer-support
  {
    pathname: '/Chat',
    screen: Chat,
  },

  //seller-support
  {
    pathname: '/seller-support',
    screen: SellerSupport,
  },

  //push-notifications
  ...(appBranding.tools.features.isEnabled('AdminPushNotifications')
    ? [
        {
          pathname: '/push-notifications',
          screen: PushNotifications,
        },
      ]
    : []),

  //grants
  ...(appBranding.tools.features.isEnabled('LegalCustomerGrant')
    ? [
        {
          pathname: '/grants',
          screen: CustomerGrants,
        },
        {
          pathname: '/applied-grants',
          screen: AppliedGrands,
        },
      ]
    : []),
];

export default screens;
