const env = {
  AWS: {
    S3: {
      Region: 'env::AWS::S3::Region',
      AccessKeyId: 'env::AWS::S3::AccessKeyId',
      AccessSecretKey: 'env::AWS::S3::AccessSecretKey',
      BucketName: 'env::AWS::S3::BucketName',
      InvoiceBucketName: 'env::AWS::S3::InvoiceBucketName',
      Domain: 'env::AWS::S3::Domain',
      ClientBucketName: 'env::AWS::S3::ClientBucketName',
    },
    Cognito: {
      UseNicknameAsIdentifier: 'env::AWS::Cognito::UseNicknameAsIdentifier',
      Region: 'env::AWS::Cognito::Region',
      AccessKeyId: 'env::AWS::Cognito::AccessKeyId',
      AccessSecretKey: 'env::AWS::Cognito::AccessSecretKey',
      UserPoolID: 'env::AWS::Cognito::UserPoolID',
      ClientID: 'env::AWS::Cognito::ClientID',
      ClientSecret: 'env::AWS::Cognito::ClientSecret',
    },
    SES: {
      Region: 'env::AWS::SES::Region',
      AccessKeyId: 'env::AWS::SES::AccessKeyId',
      AccessSecretKey: 'env::AWS::SES::AccessSecretKey',
      TemplatePrefix: 'env::AWS::SES::TemplatePrefix',
      SenderName: 'env::AWS::SES::SenderName',
      SenderEmail: 'env::AWS::SES::SenderEmail',
    },
  },
  Firebase: {
    RealtimeDatabase: {
      Region: 'env::Firebase::RealtimeDatabase::Region',
      Name: 'env::Firebase::RealtimeDatabase::Name',
    },
    RemoteConfig: {
      ProjectID: 'env::Firebase::RemoteConfig::ProjectID',
      ClientEmail: 'env::Firebase::RemoteConfig::ClientEmail',
      PrivateKey: 'env::Firebase::RemoteConfig::PrivateKey',
    },
    CloudMessaging: {
      ProjectID: 'env::Firebase::CloudMessaging::ProjectID',
      ProjectKeyID: 'env::Firebase::CloudMessaging::ProjectKeyID',
      ClientID: 'env::Firebase::CloudMessaging::ClientID',
      ClientEmail: 'env::Firebase::CloudMessaging::ClientEmail',
      PrivateKey: 'env::Firebase::CloudMessaging::PrivateKey',
    },
  },
  Map: {
    GoogleMap: {
      API: {
        ApiKey: 'env::Map::GoogleMap::API::ApiKey',
      },
      Client: {
        ApiKey: 'env::Map::GoogleMap::Client::ApiKey',
      },
    },
    YandexMap: {
      Client: {
        ApiKey: 'env::Map::YandexMap::Client::ApiKey',
      },
      API: {
        GeocoderHttp: {
          ApiKey: 'env::Map::YandexMap::API::GeocoderHttp::ApiKey',
        },
        GeoSuggest: {
          ApiKey: 'env::Map::YandexMap::API::GeoSuggest::ApiKey',
        },
      },
    },
  },
  Delivery: {
    HayPost: {
      API: {
        Username: 'env::Delivery::HayPost::API::Username',
        Password: 'env::Delivery::HayPost::API::Password',
        CustomerID: 'env::Delivery::HayPost::API::CustomerID',
      },
    },
    MegaMove: {
      API: {
        Username: 'env::Delivery::MegaMove::API::Username',
        Password: 'env::Delivery::MegaMove::API::Password',
      },
      Vendor: {
        TokenSignature: 'env::Delivery::MegaMove::Vendor::TokenSignature',
        Username: 'env::Delivery::MegaMove::Vendor::Username',
        Password: 'env::Delivery::MegaMove::Vendor::Password',
      },
    },
    ZZLogistics: {
      API: {
        User: {
          FirstName: 'env::Delivery::ZZLogistics::API::User::FirstName',
          LastName: 'env::Delivery::ZZLogistics::API::User::LastName',
          Email: 'env::Delivery::ZZLogistics::API::User::Email',
          Password: 'env::Delivery::ZZLogistics::API::User::Password',
        },
      },
      Vendor: {
        AccessToken: 'env::Delivery::ZZLogistics::Vendor::AccessToken',
      },
    },
    Yandex: {
      API: {
        AuthToken: 'env::Delivery::Yandex::API::AuthToken',
      },
    },
  },
  Payment: {
    AmeriaBank: {
      ClientID: 'env::Payment::AmeriaBank::ClientID',
      Username: 'env::Payment::AmeriaBank::Username',
      Password: 'env::Payment::AmeriaBank::Password',
    },
    Yookassa: {
      Username: 'env::Payment::Yookassa::Username',
      Password: 'env::Payment::Yookassa::Password',
    },
  },
  ProductSource: {
    Ali1688: {
      AppKey: 'env::ProductSource::Ali1688::AppKey',
      AppSecret: 'env::ProductSource::Ali1688::AppSecret',
      AccessToken: 'env::ProductSource::Ali1688::AccessToken',
    },
  },
  AI: {
    OpenAI: {
      ApiKey: 'env::AI::OpenAI::ApiKey',
      Model: 'env::AI::OpenAI::Model',
    },
  },
  LegalInfo: {
    DaData: {
      AuthToken: 'env::LegalInfo::DaData::AuthToken',
    },
  },
  PlatformSettings: {
    ProductPriceIncreasePercent:
      'env::PlatformSettings::ProductPriceIncreasePercent',
    YandexExpressDeliveryAdditionalCost:
      'env::PlatformSettings::YandexExpressDeliveryAdditionalCost',
  },
  BranchIO: {
    LiveKey: 'env::BranchIO::LiveKey',
    TestKey: 'env::BranchIO::TestKey',
  },
};

export default env;
