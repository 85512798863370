import React, { useCallback } from 'react';
import appBranding from '@happylife-a/branding';
import StarRatings from 'react-star-ratings';
import firebase from '@happylife-a/firebase';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Text, useTheme } from '@chakra-ui/react';

function RatingsAndFavorite({ selectable, productDetail, product }) {
  const { isUnknownUser, isGuestUser } = webCore.contexts.useUser();
  const { toggleFavorite } = webCore.contexts.useFavorites();
  const { dispatch } = webApp.contexts.useModal();
  const theme = useTheme();

  const onFavoriteToggle = useCallback(() => {
    toggleFavorite(productDetail.id, {
      onCreate: ({ id: favoriteId }) => {
        if (
          !isGuestUser &&
          appBranding.tools.features.isEnabled('Collection')
        ) {
          dispatch({
            type: 'open',
            modal: webApp.constants.ENUM_MODAL_PRODUCT_ADD_COLLECTION,
            props: {
              favoriteId: favoriteId,
              productDetailId: productDetail.id,
            },
          });
        }
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_FAVORITE_ADD,
          {
            productId: productDetail.id,
          }
        );
      },
      onDelete: () => {
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_FAVORITE_REMOVE,
          {
            productId: productDetail.id,
          }
        );
      },
    });
  }, [productDetail.id, isGuestUser]);

  const onSignInClick = useCallback(() => {
    const onCloseModal = () => dispatch({ type: 'close' });

    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_SIGN_IN,
      props: { onClose: onCloseModal },
    });
  }, []);
  const starRatedColor = theme.colors.orange[500];
  const starEmptyColor = theme.colors.white[50];
  return (
    <Flex justifyContent="space-between">
      <Flex>
        <StarRatings
          rating={product?.reviewAverage}
          numberOfStars={5}
          starRatedColor={starRatedColor}
          starWidthAndHeight="13px"
          starDimension={selectable ? '14px' : '16px'}
          starSpacing="2px"
          starEmptyColor={starEmptyColor}
          className="star-ratings"
        />
        {product?.reviewCount !== 0 && (
          <Text variant="body-reg-sm" mt={1} ml={1}>
            ({product?.reviewCount})
          </Text>
        )}
      </Flex>

      <webApp.components.FavoriteButton
        variant="variant1"
        productDetailId={productDetail?.id}
        onToggle={isUnknownUser ? onSignInClick : onFavoriteToggle}
      />
    </Flex>
  );
}

export default RatingsAndFavorite;
