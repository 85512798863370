import React from 'react';
import webCore from '@happylife-a/web-core';
import { Box, Text, Button, Center } from '@chakra-ui/react';

function OpenQuickViewButton({ isHovering, openQuickViewModal }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Box
      width="100%"
      transition="opacity .25s"
      opacity={isHovering ? 1 : 0}
      background="white.50"
      borderLeftWidth={1}
      borderRightWidth={1}
      borderBottomWidth={1}
      position="absolute"
      borderColor="grey.300"
      borderRadius="0 0 10px 10px"
      right="0"
    >
      <Center mt={4} zIndex={9}>
        <Button
          variant="darkPrimary"
          borderColor="white.50"
          borderRadius="30px"
          display={{ base: 'none', md: 'block' }}
          onClick={openQuickViewModal}
          mb={3}
        >
          <Text variant="sub-head-reg" color="white.50">
            {t('Quick view')}
          </Text>
        </Button>
      </Center>
    </Box>
  );
}

export default OpenQuickViewButton;
