/* eslint-disable import/no-anonymous-default-export */
import '@happylife-a/polyfill';

import * as nativeRouter from 'react-router-dom';

import * as components from './components';
import * as compositions from './compositions';
import configure from './configure';
import * as constants from './constants';
import * as contexts from './contexts';
import * as drivers from './drivers';
import * as helpers from './helpers';
import * as hooks from './hooks';
import * as providers from './providers';
import * as routing from './routing';
import * as screens from './screens';
import { theme, UiProvider } from './theme';

const router = {
  ...nativeRouter,
  useNavigate: components.useLocalizedNavigate,
  useLocalizedUpdateState: components.useLocalizedUpdateState,
  Navigate: components.LocalizedNavigate,
};

// named exports
export {
  router,
  helpers,
  components,
  compositions,
  hooks,
  contexts,
  constants,
  drivers,
  providers,
  routing,
  theme,
  UiProvider,
  screens,
  configure,
};

// default exports
export default {
  router: router,
  helpers: helpers,
  components: components,
  compositions: compositions,
  hooks: hooks,
  contexts: contexts,
  constants: constants,
  drivers: drivers,
  providers: providers,
  routing: routing,
  theme: theme,
  UiProvider: UiProvider,
  screens: screens,
  configure: configure,
};
