import React from 'react';
import s3Storage from '@happylife-a/s3-storage';
import { Image } from '@chakra-ui/react';

export default function SlideItem({ imageOrVideo }) {
  const imageUrl = s3Storage.helpers.read.buildUrlProduct(
    imageOrVideo.preview,
    '@0.75'
  );

  return (
    <Image
      src={imageUrl}
      alt="Product image"
      objectFit="contain"
      w="100%"
      h="100%"
      loading="lazy"
    />
  );
}
