import '@happylife-a/polyfill';
import './configureBranding';

import React from 'react';
import webApp from '@happylife-a/web-app';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <App RouterComponent={webApp.router.BrowserRouter} />
);
