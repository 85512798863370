import React, { useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import VideoPlayer from '../VideoPlayer';
import { Box, Center } from '@chakra-ui/react';
import { PlayVideo as PlayVideoIcon } from '../../theme/icons';

export const folders = s3Storage.configs.folders;

function S3Video({
  folder,
  videoKey,
  poster,
  posterSize = 'original',
  autoplay = false,
  ...props
}) {
  const [play, setPlay] = useState(autoplay);

  const sourceUrl = videoKey?.startsWith('http')
    ? videoKey
    : s3Storage.helpers.read.buildUrl(folder, videoKey, 'original');

  if (play) {
    return <VideoPlayer source={sourceUrl} poster={poster} {...props} />;
  }

  const posterUrl = poster?.startsWith('http')
    ? poster
    : s3Storage.helpers.read.buildUrl(folder, poster, posterSize);

  return (
    <Box
      backgroundImage={posterUrl}
      backgroundSize="100% 100%"
      width="full"
      height="full"
      cursor="pointer"
      onClick={() => setPlay(true)}
    >
      <Center
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        backgroundColor="rgba(0, 0, 0, 0.5)"
      >
        <Box background="white" padding={5} borderRadius="50%">
          <PlayVideoIcon width={10} height={10} color="black" fill="black" />
        </Box>
      </Center>
    </Box>
  );
}

S3Video.folders = folders;
export default S3Video;
