import React, { useState, useRef, useEffect } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex } from '@chakra-ui/react';
import { useScreenSize } from '../../../contexts';
import { theme } from '../../../theme';
import S3Image from '../../S3Image';
import S3Video from '../../S3Video';
import SliderController from '../../SliderController';

const ReactImageZoom = webCore.helpers.dynamic(
  () => import('react-image-zoom')
);

const MAX_THUMBNAILS_COUNT = 4;
const scrollbarThumbWidth = 2;
const scrollbarTrackWidth = 3;

export default function SliderV2({ imagesAndVideos, mainBoxH, footerBoxW }) {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const thumbnailContainer = useRef(null);
  const thumbItemContainer = useRef(null);
  const mainBoxRef = useRef(null);
  const imageRef = useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(null);

  const thumbItemWidth = 105;

  const thumbnailWidth = !(imagesAndVideos.length <= MAX_THUMBNAILS_COUNT + 1)
    ? `${imagesAndVideos.length * thumbItemWidth + 1}px`
    : 'full';

  const onSlideChange = (index) => {
    setActiveSlideIndex(index);
  };

  const nextSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex + 1 === imagesAndVideos.length) {
        thumbnailContainer.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        return 0;
      }

      if (oldActiveSlideIndex >= MAX_THUMBNAILS_COUNT - 1) {
        // eslint-disable-next-line no-unused-expressions
        thumbnailContainer.current.scrollTo({
          top: 0,
          left: thumbnailContainer.current.scrollLeft + thumbItemWidth + 1,
          behavior: 'smooth',
        });
      }

      return oldActiveSlideIndex + 1;
    });
  };
  const prevSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (activeSlideIndex - 1 === -1) {
        thumbnailContainer.current.scrollTo({
          top: 0,
          left:
            (imagesAndVideos.length - MAX_THUMBNAILS_COUNT) * thumbItemWidth,
          behavior: 'smooth',
        });
        return imagesAndVideos.length - 1;
      }
      thumbnailContainer.current.scrollTo({
        top: 0,
        left: thumbnailContainer.current.scrollLeft - thumbItemWidth,
        behavior: 'smooth',
      });

      return oldActiveSlideIndex - 1 === -1
        ? imagesAndVideos.length
        : oldActiveSlideIndex - 1;
    });
  };

  useEffect(() => {
    setActiveSlideIndex(0);
  }, []);

  const screenSize = useScreenSize();

  const activeItem = imagesAndVideos?.[activeSlideIndex];
  const imageSrc = s3Storage.helpers.read.buildUrlProduct(
    activeItem?.preview,
    '@0.75'
  );

  if (!activeItem) {
    return null;
  }

  return (
    <Box flex={1}>
      <img
        ref={imageRef}
        src={imageSrc}
        alt="Original"
        style={{ display: 'none' }}
        onLoad={() => {
          const { naturalWidth, naturalHeight } = imageRef.current;
          setImageSize({ width: naturalWidth, height: naturalHeight });
        }}
      />

      <Box
        h={mainBoxH}
        pos="relative"
        borderRadius="10px"
        ref={mainBoxRef}
        className="slider-main-div"
        cursor="pointer"
      >
        {imagesAndVideos?.length > 1 && screenSize.isMinTablet && (
          <SliderController
            variant="variant2"
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        )}

        {screenSize.isMinTablet ? (
          <>
            {activeItem?.type === 'image' ? (
              <ReactImageZoom
                width={409}
                height={mainBoxH}
                img={imageSrc}
                zoomWidth={mainBoxRef.current?.offsetWidth * 2 + 50}
                offset={{ vertical: 0, horizontal: 10 }}
                zoomLensStyle={`opacity: 0.45; background-color: ${theme.colors.primary[500]}`}
                {...(imageSize?.width < 850 && { scale: '1' })}
              />
            ) : (
              <Flex
                position="relative"
                overflow="hidden"
                borderRadius="10px"
                height="full"
              >
                <S3Video
                  folder={S3Video.folders.FOLDER_PRODUCTS}
                  videoKey={activeItem.source}
                  poster={activeItem.poster}
                  posterSize="@0.5"
                />
              </Flex>
            )}
          </>
        ) : (
          <webApp.components.Slider
            variant="variant4"
            imagesAndVideos={imagesAndVideos}
            onSlideChange={onSlideChange}
            activeSlideIndex={activeSlideIndex}
          />
        )}
      </Box>
      <Box
        w={footerBoxW}
        overflowY="hidden"
        overflowX="auto"
        ref={thumbnailContainer}
        css={{
          '&::-webkit-scrollbar': {
            width: `${scrollbarThumbWidth}px`,
          },
          '&::-webkit-scrollbar-track': {
            width: `${scrollbarTrackWidth}px`,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: `${2 * scrollbarTrackWidth}px`,
          },
        }}
      >
        <Flex ref={thumbItemContainer} w={thumbnailWidth} mt={2} gap={4}>
          {imagesAndVideos?.map((imageOrVideo, idx) => (
            <Box
              key={`product-image-${idx}`}
              w="89px"
              h="100px"
              borderColor={
                idx === activeSlideIndex ? 'primary.500' : 'transparent'
              }
              borderWidth={2}
              transition="border 200ms"
              onClick={() => onSlideChange(idx)}
              borderRadius="10px"
              overflow="hidden"
              cursor="pointer"
            >
              <S3Image
                folder={S3Image.folders.FOLDER_PRODUCTS}
                imageKey={imageOrVideo.preview}
                size="166x200"
                alt="Product Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
