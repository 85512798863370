import React from 'react';
import { Text } from '@chakra-ui/react';

import Variant1 from './variant1';
import Variant2 from './variant2';

const Variants = {
  variant1: Variant1,
  variant2: Variant2,
};

export default function FavoriteButton({
  variant,
  productDetailId,
  onToggle,
  isOutOfStock,
}) {
  const FavoriteButtonComponent = Variants[variant];

  if (!FavoriteButtonComponent) {
    return (
      <Text textAlign="center" fontWeight="bold">
        Invalid Favorite Button variant provided
      </Text>
    );
  }

  return (
    <FavoriteButtonComponent
      productDetailId={productDetailId}
      onToggle={onToggle}
      isOutOfStock={isOutOfStock}
    />
  );
}
