import React, { useState } from 'react';
import appBranding from '@happylife-a/branding';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import {
  Button,
  Stack,
  Input,
  FormControl,
  Icon,
  Heading,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Image,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AiOutlineEye } from 'react-icons/ai';

export default function LoginForm({ isLoading, onSubmit, showError, message }) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      identifier: '',
      password: '',
    },
  });

  const { t } = webCore.contexts.useTranslation();
  return (
    <Stack
      as="form"
      maxW="581px"
      w="100%"
      flexDirection="column"
      justifyContent="flex-end"
      spacing={3}
      bg="white"
      borderRadius="12px"
      px={11}
      py={8}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={8} alignItems="center">
        <Image
          alt="logo"
          src={appBranding.configs.assets.getAsset(
            'logos/svg/square-colored.svg'
          )}
          w="80px"
          h="80px"
          objectFit="cover"
        />
        <Stack spacing={2}>
          <Heading as="h3" variant="h3-bold">
            {t('Welcome back!')}
          </Heading>
          <Text variant="body-reg-lg" color="grey.600">
            {t('Login to your account')}
          </Text>
        </Stack>
      </Stack>
      <Stack pt={14} spacing={6}>
        <FormControl>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              height={12}
              // eslint-disable-next-line react/no-children-prop
              children={
                <Icon as={webApp.theme.icons.Email} fill="none" w={5} h={5} />
              }
            />
            <Input
              border="none"
              bg={`${errors.identifier ? 'red.50' : 'blue.50'}`}
              borderRadius="8px"
              height={12}
              _focus={{ border: 'none', shadow: 'none' }}
              // @TODO: proper validation for email / username
              {...register('identifier', {
                required: 'This entry is required',
              })}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <InputGroup>
            <InputLeftElement
              height={12}
              pointerEvents="none"
              // eslint-disable-next-line react/no-children-prop
              children={
                <Icon
                  as={webApp.theme.icons.LockIcon}
                  fill="none"
                  w={5}
                  h={5}
                />
              }
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              border="none"
              bg={`${errors.password ? 'red.50' : 'blue.50'}`}
              borderRadius="8px"
              height={12}
              _focus={{ border: 'none', shadow: 'none' }}
              {...register('password', { required: true })}
            />
            <InputRightElement height={12}>
              <Button variant="link" onClick={handleClick}>
                {showPassword ? (
                  <Icon as={AiOutlineEye} fill="black" w={6} h={6} />
                ) : (
                  <Icon as={webApp.theme.icons.EyePassword} w={5} h={5} />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          {showError && (
            <Text fontSize="md" color="red.200" mt={1}>
              {message}
            </Text>
          )}
        </FormControl>
      </Stack>
      <Stack alignItems="end" pt={4}>
        <webApp.components.LocalizedLink to={'/dashboard/recover-password'}>
          <Text as="span" variant="body-reg-lg" color="primary.500">
            {t('AppRecoverPassword')}
          </Text>
        </webApp.components.LocalizedLink>
      </Stack>
      <Stack alignItems="center" pt={12}>
        <Button
          variant="filled"
          isLoading={isLoading}
          isDisabled={
            isLoading || watch('identifier') === '' || watch('password') === ''
          }
          type="submit"
          fontSize="2xl"
          fontWeight={600}
          w={180}
          h={53}
          borderRadius={12}
        >
          Sign in
        </Button>
      </Stack>
    </Stack>
  );
}
