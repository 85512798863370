import React, { useMemo } from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { IconButton, Icon, Flex } from '@chakra-ui/react';

export default function FavoriteButtonV2({
  productDetailId,
  onToggle,
  isOutOfStock,
}) {
  const { isFavorite, favorites } = webCore.contexts.useFavorites();

  const isFavoriteItem = useMemo(
    () => isFavorite(productDetailId),
    [isFavorite, productDetailId, favorites?.ids]
  );

  const onClick = (e) => {
    e.preventDefault();
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <Flex
      minW="47px"
      h="47px"
      borderRadius="50%"
      borderWidth={1}
      borderStyle="solid"
      borderColor="primary.500"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton
        variant="transparent"
        height="auto"
        justifyContent="flex-end"
        minWidth="unset"
        isDisabled={isOutOfStock}
        icon={
          <Icon
            as={webApp.theme.icons.FavoriteIcon}
            fill={isFavoriteItem ? 'primary.500' : 'none'}
            stroke={isFavoriteItem ? 'none' : 'primary.500'}
            h={6}
            w={6}
            ml="1px"
            mt="1px"
          />
        }
        onClick={onClick}
      />
    </Flex>
  );
}
