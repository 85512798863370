import React from 'react';
import { Center, Grid, GridItem, Spinner } from '@chakra-ui/react';
import ProductCard from '../../ProductCard';

function RenderProductsGrid({
  isInitialLoading,
  products,
  onSelectProduct,
  selectedProducts,
  isMobile,
}) {
  if (isInitialLoading || !products) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Grid templateColumns="repeat(10, 1fr)" gap={2} maxW="100%">
      {products.map((productDetail) => (
        <GridItem
          key={`product-${productDetail.id}`}
          colSpan={{ base: 6, md: 2 }}
        >
          <ProductCard
            product={productDetail.product}
            productDetail={productDetail}
            enableHover={false}
            selectable={true}
            onSelectProduct={onSelectProduct}
            selectedProducts={selectedProducts}
            isMobile={isMobile}
          />
        </GridItem>
      ))}
    </Grid>
  );
}

export default RenderProductsGrid;
