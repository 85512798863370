import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Flex } from '@chakra-ui/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import S3Video from '../../S3Video';
import S3Image from '../../S3Image';

export default function SliderV4({
  imagesAndVideos,
  mainBoxH,
  onSlideChange,
  activeSlideIndex,
}) {
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    onSlideChange(swiper.activeIndex);
  };

  useEffect(() => {
    if (
      swiperRef.current &&
      swiperRef.current.swiper &&
      typeof activeSlideIndex === 'number' &&
      !isNaN(activeSlideIndex)
    ) {
      swiperRef.current.swiper.slideTo(activeSlideIndex);
    }
  }, [activeSlideIndex]);

  return (
    <Box h="full" bgColor="grey.50" borderRadius="10px" overflow="hidden">
      <Swiper
        style={{ height: '100%' }}
        onSlideChange={handleSlideChange}
        ref={swiperRef}
      >
        {imagesAndVideos?.map((imageOrVideo) => (
          <SwiperSlide
            key={`product-image-slide-${imageOrVideo.id}`}
            style={{ height: mainBoxH }}
          >
            <Flex
              position="relative"
              overflow="hidden"
              borderRadius="10px"
              height="full"
            >
              {imageOrVideo?.type === 'image' ? (
                <S3Image
                  folder={S3Image.folders.FOLDER_PRODUCTS}
                  imageKey={imageOrVideo.preview}
                  size="410x428"
                  alt="Product Image"
                  objectFit="contain"
                  w="100%"
                  h="100%"
                  borderRadius="10px"
                  bgColor="grey.50"
                />
              ) : (
                <S3Video
                  folder={S3Video.folders.FOLDER_PRODUCTS}
                  videoKey={imageOrVideo.source}
                  poster={imageOrVideo.preview}
                  posterSize="@0.5"
                />
              )}
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
