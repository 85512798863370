import { useCallback } from 'react';
import webApp from '@happylife-a/web-app';

function useShowProduct({ productSlug, productId, productDetailId }) {
  const navigate = webApp.router.useNavigate();
  const { dispatch } = webApp.contexts.useModal();

  const screenSize = webApp.contexts.useScreenSize();

  const openQuickViewModal = useCallback(() => {
    if (!productId || !productDetailId) {
      return;
    }

    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_QUICK_VIEW,
      props: {
        productId: productId,
        activeDetailId: productDetailId,
      },
    });
  }, [productId, productDetailId]);

  const navigateToProductPage = useCallback(() => {
    if (!productSlug || !productDetailId) {
      return;
    }

    navigate(`/products/${productSlug}?detailId=${productDetailId}`);
  }, [productSlug, productDetailId]);

  const displayProductInfo = useCallback(() => {
    if (screenSize.isMinTablet) {
      openQuickViewModal();
    } else {
      navigateToProductPage();
    }
  }, [screenSize.isMinTablet]);

  return {
    openQuickViewModal: openQuickViewModal,
    navigateToProductPage: navigateToProductPage,
    displayProductInfo: displayProductInfo,
  };
}

export default useShowProduct;
